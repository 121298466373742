.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-block-start: var(--spacing-micro);
  margin-inline-end: var(--spacing-micro);
  margin-block-end: var(--spacing-micro);
  margin-inline-start: var(--spacing-micro);
}

.checkbox {
  all: unset;
  background-color: var(--color-background-alternative-faint);
  border-radius: var(--border-radius-default);
  border: 1px solid var(--color-secondary-medium);
  width: var(--spacing-medium);
  height: var(--spacing-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    border: 1px solid var(--color-primary-medium);
  }
}

.checkbox[data-focus-visible-added] {
  &:focus {
    box-shadow: var(--shadow-small);
  }
}

.checkbox[data-state="checked"],
.checkbox[data-state="indeterminate"] {
  border: 1px solid var(--color-borders-hairline);
}
.checkbox[disabled] {
  background-color: var(--color-secondary-medium);
}

.label {
  padding-inline-start: var(--spacing-xsmall);
  font-size: var(--font-size-normal);
  line-height: var(--line-height-normal);
  user-select: none;
}

.disabled {
  color: var(--color-secondary-medium);
}

.indicator {
  background: var(--color-primary-medium);
  border-radius: var(--border-radius-default);
  width: 100%;
  height: 100%;
}

.disabledIndicator {
  background-color: var(--color-secondary-medium);
}
